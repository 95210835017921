<template>
  <div class="detail-index-bg">
    <div class="detail-index" v-loading="pageLoading">
      <Crumbs/>
      <!-- <div class="detail-index-crumbs">
        <router-link
          class="detail-index-crumbs-up" style="margin-right:0.1rem"
          :to="{ name: 'culture_activity' }"
          >群文活动</router-link
        >/<div class="detail-index-crumbs-this" style="margin-left:0.1rem">{{info.title}}</div>
      </div> -->
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img style="width: 100%; height: 100%" :src="info.banner" alt="" />
        </div>
        <div class="detail-index-top-right">
          <div class="right-top">
          <div class="activity-detail-top-title">{{ info.title }}</div>
          <!-- 1已结束，2进行中，3待开始，4报名中，5待报名 -->
          <!-- <div class="activity-detail-top-status">
              
            <div
              class="activity-detail-top-status-finished"
              v-if="info.status == 1"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-applying"
              v-else-if="info.status == 2"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-applyingend"
              v-else-if="info.status == 3"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-signing"
              v-else-if="info.status == 4"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-notbegin"
              v-else-if="info.status == 5"
            >
              {{ info.status_text }}
            </div>
          </div> -->
          <div class="activity-detail-top-des">
            <ul class="activity-detail-top-des-ul">
              <li class="activity-detail-top-des-ul-li">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/%20webculture/jm/activity-detail-menbers.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label" v-if="info.limit_num!=0">
                  <span>活动名额：</span>
                  {{ info.limit_num }}
                </div>
                <div class="activity-detail-top-des-ul-li-label" v-else>
                  <span>活动名额：</span>无人数限制
                </div>
              </li>
              <li class="activity-detail-top-des-ul-li" v-if="info.sponsor">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/%20webculture/jm/activity-detail-number.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label">
                  <span>联系人：</span>
                  {{ info.sponsor }}
                </div>
              </li>
              <li class="activity-detail-top-des-ul-li" v-if="info.telephone">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_phone_icon.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label">
                  <span>联系电话：</span>
                  {{ info.telephone }}
                </div>
              </li>
              <li class="activity-detail-top-des-ul-li" v-if="info.team_name">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/%20webculture/jm/activity-detail-team.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label">
                  <span>发起团队：</span>
                  {{ info.team_name }}
                </div>
              </li>
              <li class="activity-detail-top-des-ul-li">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_date_icon.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label" style="font-family: PingFangSCRegular;">
                  <span>报名时间：</span>
                  {{ info.sign_start_time }}～ {{ info.sign_end_time }}
                </div>
              </li>
              <li class="activity-detail-top-des-ul-li">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_date_icon.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label" style="font-family: PingFangSCRegular;">
                  <span>活动时间：</span>
                  {{ info.active_start_time }}～ {{ info.active_end_time }}
                </div>
              </li>
              <li class="activity-detail-top-des-ul-li" v-if="info.address">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_address_icon.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label">
                  <span>活动地址：</span>
                  {{ info.address }}
                </div>
              </li>
            </ul>
          </div>
          </div>
          <!-- 1活动已结束，2活动进行中，3报名已结束活动未开始，4预约(报名)中，5报名未开始 -->
          <div class="activity-detail-top-button" @click="handleServe" style="cursor: pointer"
           v-if="info.status == 4">
            我要报名
          </div>
          <div class="activity-detail-top-button pink"
           v-if="info.status == 5">
            报名未开始
          </div>
          <div class="activity-detail-top-button pink"
           v-if="info.status == 3">
            报名已结束
          </div>
          <div class="activity-detail-top-button pink"
           v-if="info.status == 2">
            活动进行中
          </div>
          <div class="activity-detail-top-button pink"
           v-if="info.status == 1">
            活动已结束
          </div>
          <!-- <div
            class="activity-detail-top-button"
            v-if="info.status == 4"
            @click="handleServe"
            style="cursor: pointer"
          >
            我要报名
          </div>
          <div
            class="activity-detail-top-button background-pink"
            v-else
          >
            我要报名
          </div> -->

        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">活动详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box" v-if="info.summary">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  活动简介
                </div>
              </div>
              <div
                class="detail-index-bottom-content-bottom-box-bottom"
                v-html="info.summary"
              >
                <div>{{ info.summary }}</div>
              </div>
            </div>
            <div class="detail-index-bottom-content-bottom-box" v-if="info.service_require">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  服务要求
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div>{{ info.service_require }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mydialog
      v-if="formDialogShow"
      :keyword="'扫码预定活动'"
      :isShow="formDialogShow"
      :src="detailSrc"
      @close="formDialogShow = false"
    />
  </div>
</template>

<script>
import { getActivityDetail, getListRound } from "@/api/activity/activity";
import { genQRCode } from "@/api/common";
import mydialog from "@/components/myDialog";
import Crumbs from "@/views/components/crumbs";
// import merge from "webpack-merge";
export default {
  components: { mydialog,Crumbs },
  data() {
    return {
      pageLoading: false,
      info: {},
      detailSrc: "",
      formDialogShow: false,
      aid: process.env.VUE_APP_AID,
      id: null,
      popupTitle: "扫描二维码参与文化活动",
      detailType: "activity",
      activity_team_list: [],
      active_round_array:[],
      tid:"",
    };
  },

  created() {
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.tid = this.$route.query.tid;
    this.getListR()
    // this.getInfo();
  },
  watch: {
    $route(newV) {
      document.documentElement.scrollTop = 0;
      this.tid = newV.query.tid;
      this.getListR()
    },
  },
  methods: {
    changeInfo(id) {
      console.log(1);
      //   this.$router.replace( { id: id } );
      this.$router.push(`detail?id=${id}`);
      this.$router.go();
      document.documentElement.scrollTop = 0;
      //   this.id = id;
    },
    handleClosed() {},
    getInfo() {
      getActivityDetail({ id: this.tid }).then((res) => {
        console.log(res);
        const regex = new RegExp("<img", "gi");
        res.data.summary = res.data.summary.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto;"`
        );
        this.info = res.data;
        console.log(this.active_round_array)
        for(var i = 0;i < this.info.active_round.length; i++) {
          for(var j = 0;j < this.active_round_array.length;j++) {
            if(this.info.active_round[i].id == this.active_round_array[j].id) {
              console.log(this.info.active_round[i])
              this.info.active_round[i].limit_num = this.active_round_array[j].limit_num
              this.info.active_round[i].signup_num = this.active_round_array[j].signup_num
            }
          }
        }
        console.log(this.info.active_round)
      });
    },
    getListR() {
      getListRound({ active_id: this.tid }).then((res) => {
        
        this.active_round_array = res.data
        this.getInfo();
      });
    },
    handleServe() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/volunteer/activity/activityDetail?id=" +
          this.tid +
          "&aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid: process.env.VUE_APP_AID,
      };
      this.formDialogShow = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/volunteerActive_default_detail.scss";
@import "../../assets/scss/dialog.scss";
</style>
